/* You can add global styles to this file, and also import other style files */
@import "../node_modules/primeicons/primeicons.css";

$gutter: 1rem; //for primeflex grid system
body {
    padding: 0;
    margin: 0;
}

// html {
//     scroll-behavior: smooth;
// }
// body {
//   background-color: #fafafa;
//   font-size: 16px;
//   font-family: Roboto, "Helvetica Neue", sans-serif;
//   line-height: 1.5;
//   font-kerning: normal;
// }

.ck-editor__editable {
  max-height: 350px;
  min-height: 350px;
}

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

blockquote {
  display: block;
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eee;
}

.spacer {
  flex: 1 1 auto;
}

img {
  max-width: 100%;
}

.snackbar-ribon {
  color: #ffffff;
  background: #17a2b8;
}
